import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="boston1836">
<h1>Il periodo americano</h1>
<p>Antonio, dopo aver rischiato di cambiare per sempre la storia d’Italia e aver rischiato di morire, lascia temporaneamente l’Europa in cerca di realizzazione e di nuove avventure.</p>
<ImgCentro nome="boston.PNG" alt="Boston" didascalia="Boston"/>
<p>Dopo essere approdato a New York si reca a Boston per incontrare Edward Everett, il quale gli comunica che al momento non c’è nessun posto vacante per una cattedra di letteratura italiana.</p>
<p>Dopo questo primo deludente incontro e dopo essersi ritrovato a contare sulla carità dei connazionali italiani e di quella del vescovo di Boston, Everett viene in suo soccorso e lo paga per ricevere lezioni di italiano, in particolare sulla figura di Dante, alle sue figlie.</p>
<ImgSinistra nome="dante.PNG" alt="Dante" />
<p>Dopo aver trovato alloggio nel quartiere di Charleston riesce, tramite alcune nuove conoscenze, a trovare un impiego alla Young Ladies’ Academy di Cambridge, un istituto privato di educazione femminile.<br />
Sebbene la scuola si trovi a un chilometro da Harvard, non ha nulla a che fare con la prestigiosa università; tuttavia, la vicinanza tra le due istituzioni consente ad Antonio di fare la conoscenza di eminenti personaggi del mondo della cultura del tempo, quali: Ralph W. Emerson, Henry W. Longfellow, Henri Ware Jr. e James Palfrey.</p>
<p>Longfellow propone ad Antonio la pubblicazione delle sue poesie italiane (Romanze, 1838), Ware lo sollecita a tenere un ciclo di conferenze che avranno molto successo (la prima delle quali ha come tema “Maria Luigia duchessa di Parma”), mentre Palfrey sollecita la sua collaborazione come critico alla rivista letteraria North American Review. In questo periodo Antonio si dedica con grande volontà allo studio dell’inglese, finché non comincia addirittura a pensare in questa lingua.</p>
<ImgDestra nome="bandiera.PNG" alt="Bandiera" />
<p>Tuttavia, nonostante comincino a delinearsi per lui i presupposti di una vita agiata dedita all’insegnamento e alla scrittura, la sua irrequietezza, sommata ad alcune delusioni di natura professionale (l’impossibilità di ottenere una cattedra di letteratura italiana) e amorosa (un rifiuto), lo spingono, dopo due anni e mezzo di permanenza (ottobre 1836-maggio 1839), a lasciare gli Stati Uniti e a far ritorno in Europa.</p>
<p>A Londra, per la precisione.</p>
<h2>Link per approfondire</h2>
<p><A href="https://it.wikipedia.org/wiki/Cambridge_(Massachusetts)">“Cambridge”</A></p>
<p><A href="https://boston.curbed.com/maps/charlestown-tour-boston">“Charleston”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/henry-wadsworth-longfellow/">“Henry W. Longfellow”</A></p>
<p><A href="https://en.wikipedia.org/wiki/Henry_Ware_Jr.">“Henri Ware Jr.”</A></p>
<p><A href="https://www.treccani.it/enciclopedia/ralph-waldo-emerson/">“Ralph W. Emerson”</A></p>
<p><A href="https://en.wikipedia.org/wiki/William_H._Prescott">“William H. Prescott”</A></p>

	</PaginaTesto>
}